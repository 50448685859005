<template>
  <div>
    <ol class="breadcrumb float-xl-right">
      <li class="breadcrumb-item">
        <router-link :to="{name:'dashboard'}">Dashboard</router-link>
      </li>
      <li class="breadcrumb-item"><a href="#">Limits</a></li>
    </ol>
    <h1 class="page-header">INDIVIDUAL BIDBOND LIMIT</h1>
    <panel noButton="true" title="MANAGE INDIVIDUAL BIDBOND LIMITS" variant="panel-default">
      <form @submit.prevent="save">
        <div class="row">
          <div class="form-group col-md-6">
            <label>Individual BidBond Limit</label>
            <vue-numeric
                v-model="individual_limit"
                class="form-control"
            />
          </div>
          <div class="form-group col-md-12">
            <button class="btn btn-primary">
              Save
            </button>
          </div>
        </div>
      </form>
    </panel>
  </div>
</template>

<script>
export default {

  data() {
    return {
      individual_limit: 0,
    }
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$axios.get('/api/v1/bid-bond-limit').then(response => {
        if(response.data.limit){
          this.individual_limit = response.data.limit;
        }
      });
    },

    save() {
      this.$axios.post('/api/v1/bid-bond-limit', this.individual_limit).then(() => {
        this.$toastr.s('The individual limit has been updated', 'Saved');
      });
    },
  }
}
</script>
